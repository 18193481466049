import React from 'react';

export const FormRadioOption = ({isSelected, option, setValueHandler}) => {

    const onClickHandler = () => setValueHandler(option);

    return (
        <button className={`form__radio__option ${isSelected ? 'form__radio__option--active' : ''}`} type="button" onClick={onClickHandler}>
            {option.label}
        </button>
    );
};

export const FormOptions = ({options, selectedOption, setValueHandler, groupKey}) => {

    if(!options){
        return null;
    }

    if(!setValueHandler){
        return null;
    }

    return (
        <div className={`form__radio__group form__radio__group--${groupKey}`}>
            {options.map((option, idx) => <FormRadioOption key={idx} isSelected={option.value===selectedOption.value} option={option} setValueHandler={setValueHandler} /> )}
        </div>
    );
};

export const FormErrorLabel = ({fieldKey, formErrors}) => {

    let error = formErrors[fieldKey];
    
    if(!error){
        return null;
    }

    return (
        <div className={`form__error form__error--${fieldKey}`}>{error}</div>
    );
};