import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { verifyUser } from '../actions';
import { navigate } from 'gatsby';
import { FormErrorLabel } from '../components/forms';
import { SEO } from '@components/SEO';
import styles from '@styles/index.module.scss';
import forms from '@styles/forms.module.scss';

function IndexPage(){

    const dispatch = useDispatch();
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const user = useSelector(state => state.currentUser);
    const currentGym = useSelector(state => state.currentGym);
    const formErrors = useSelector(state => state.formErrors);

    if(user && !currentGym){
        navigate('/app/add-gym/');
        return null;
    }

    if(user){
        navigate('/app/feed/');
        return null;
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(verifyUser(email, password));
    };

    const handleChange = (e, setCallback) => {
        setCallback(e.target.value);
    };

    return (
        <>
            <SEO title="Management Portal" />
            <div className={styles['login-page']}>
                <div className={styles['login-page__login']}>
                    <div className={styles['login-page__logo']}>
                        <a><img src="/images/gymdash-logo.svg" /></a>
                    </div>
                    <div className={styles['login-box']}>
                        <h2 className={styles['login-box__header']}>Login</h2>
                        <form onSubmit={handleSubmit} noValidate>
                            <div className={forms['form__row']}>
                                <label className={forms['form__label']} htmlFor="email">Email</label>
                                <input className={forms['form__input']} type="email" name="email" placeholder="Enter your email" value={email} onChange={(e) => handleChange(e, setEmail)} />
                                <FormErrorLabel fieldKey="email" formErrors={formErrors} />
                            </div>
                            <div className={forms['form__row']}>
                                <label className={forms['form__label']} htmlFor="password">Password</label>
                                <input className={forms['form__input']} type="password" name="password" placeholder="Enter your password" value={password} onChange={(e) => handleChange(e, setPassword)} />
                                <FormErrorLabel fieldKey="password" formErrors={formErrors} />
                                <FormErrorLabel fieldKey="is_verified" formErrors={formErrors} />
                            </div>
                            <div className={forms['form__row']}>
                                <button className={forms['form__button']} type="submit">Login</button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className={styles['login-page__details']}>

                </div>
            </div>
        </>
    );
};

export default IndexPage;
